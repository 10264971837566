import {APPLICATION_NAME, FLAGSMITH_URL} from '@vin-decoder/config';
import {environment} from '@vin-decoder/environment';
import {useFlagsmith} from 'feature-flags';
import {Center, Spinner} from 'platform/foundation';

import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Route, Routes} from 'react-router-dom';

import {isNotNil} from 'ramda';

import {ApplicationInitializeBoundary} from './components/ApplicationInitializeBoundary/ApplicationInitializeBoundary';
import {useGetVinDecoderData} from './hooks/useGetVinDecoderData';
import i18n from './i18n/i18n';
import {ErrorPage} from './pages/ErrorPage/ErrorPage';
import {MainPage} from './pages/MainPage/MainPage';

export function App() {
  const {isFlagSmithInitialized} = useFlagsmith({
    apiUrl: FLAGSMITH_URL,
    environmentID: environment.FLAGSMITH_ENV,
  });

  if (!isFlagSmithInitialized) {
    return null;
  }

  return (
    <Routes>
      <Route element={<ApplicationInitializeBoundary />}>
        <Route path="/" element={<HomePage data-testid="homePage" />} />
        <Route path=":decoderId/:param?" element={<VinDecoder data-testid="vinDecoderPage" />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
}

function HomePage() {
  const {language} = i18n;
  const languageCode = isNotNil(language) ? language.substring(0, 2) : 'en';

  window.location.href = `https://carsdata.com/${languageCode}/vin-decoder`;

  return (
    <Center height="100vh">
      <Spinner size="large" />
    </Center>
  );
}

function VinDecoder() {
  const {isLoading, isNotFound, isExpired, isError} = useGetVinDecoderData();
  const {language} = i18n;
  const languageCode = isNotNil(language) ? language.substring(0, 2) : 'en';

  if (isNotFound) {
    window.location.href = `https://carsdata.com/${languageCode}/vin-decoder/404`;
  }

  if (isExpired) {
    window.location.href = `https://carsdata.com/${languageCode}/vin-decoder/expired`;
  }

  if (isLoading || isNotFound || isExpired) {
    return (
      <Center height="100vh">
        <Spinner size="large" />
      </Center>
    );
  }

  if (isError) {
    return <ErrorPage data-testid="errorPage" />;
  }

  return (
    <HelmetProvider>
      <Helmet titleTemplate={`%s • ${APPLICATION_NAME}`} htmlAttributes={{lang: i18n.language}}>
        <meta name="description" content={APPLICATION_NAME} />
      </Helmet>

      <MainPage data-testid="mainPage" />
    </HelmetProvider>
  );
}
